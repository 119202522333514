.results-page {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

@media (max-width: 700px) {
    .results-page {
        margin-top: 60px;
        flex-direction: column;
        height: 100%;
        align-items: center;
        gap: 15px;
    }
}